@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact {
    text-align: center;

    &__title {
        padding: 1rem;
        background-color: $prim-color-3;
        margin-bottom: 1rem;

        @include tablet {
            padding: 1rem 2rem;
        }

        @include desktop {
            padding: 1rem 10rem;
        }

        & h1 {
            color: $prim-color-2;
        }


    }

    &__form {
        padding: 1rem 1rem 0rem;
        text-align: left;

        @include tablet {
            padding: 1rem 10rem;
        }

        @include desktop {
            padding: 1rem 20rem;
        }

        &-group {
            display: flex;
            flex-direction: column;
            padding-bottom: 0.75rem;

            & h2 {
                color: $prim-color-2;
            }

            & input, textarea {
                border-radius: 5px;
                color: $prim-color-2;
                background: $prim-color-3;
                border: 0 solid;
                border-bottom: 1px solid rgba($prim-color-2, 0.5);
                box-shadow: 5px 5px 5px rgba($sec-color-2, 0.5);
                // font-size: 0.9rem;
                display: block;
                padding: .5rem;

                &:focus {
                    background: $prim-color-3;
                    transition: 250ms background ease-in;
                }

                &:-webkit-autofill, 
                :-webkit-autofill:hover, 
                :-webkit-autofill:focus, 
                :-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px $prim-color-3 inset !important;
                }
            }
        }

        & button {
            margin: 0.5rem 0rem;
            padding: 10px;
            color: $support-color-1;
            font-size: 1.25rem;
            background: $prim-color-2;
            border: 1px solid $sec-color-2;
            box-shadow: 5px 5px 5px rgba($sec-color-1, 0.5);
            border-radius: 0.5rem;
            cursor: pointer;
        }
    }
}