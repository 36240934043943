@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.products {
    text-align: center;

    &__title {
        padding: 1rem;
        background-color: $prim-color-3;
        margin-bottom: 1rem;

        @include tablet {
            padding: 1rem 2rem;
        }

        @include desktop {
            padding: 1rem 10rem;
        }

        & h1 {
            color: $prim-color-2;
        }
    }

    &__nav {
        padding: 1rem;
        display: flex;
        justify-content: space-around;
        gap: 1rem;
        flex-wrap: wrap;

        @include tablet {
            padding: 1rem 2rem;
        }

        @include desktop {
            padding: 1rem 10rem;
        }

        &-products {

            & img {
                width: 5rem;
                height: 5rem;
                padding-top: 0.5rem;
                object-fit: cover;

                @include tablet {
                    width: 10rem;
                    height: 10rem;
                }

                @include desktop {
                    width: 12rem;
                    height: 12rem
                }
            }

            & a {
                &:link,
                &:visited {
                    text-decoration: none;
                    color: $prim-color-2;
                }
    
                &:hover {
                    color: $sec-color-1;
                }
            }
        }
    }

    &__list {
        margin: 1rem;
        padding-top: 1rem;
        border-top: 1px solid $prim-color-2;

        @include tablet {
            margin: 2rem;
        }

        @include desktop {
            margin: 1rem 10rem;
        }
    }
}