// breakpoints for responsive design
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

// colors
$prim-color-1: #F9C301;
$prim-color-2: #014723;
$prim-color-3: #A5C44D;

$sec-color-1: #068E46;
$sec-color-2: #61C12A;

$support-color-1: #fff8e0;
$support-color-2: #daef9f;