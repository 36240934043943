@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.pasalubong {
    // padding: 1rem;

    &__title {
        & img {
            width: 100%;
            height: auto;
            padding-bottom: 0.5rem;
            object-fit: cover;

            @include tablet {
                width: 18rem;
                height: 18rem;
            }

            @include desktop {
                width: 20rem;
                width: 20rem;
            }
        }
    }
}