@use './variables';
@use './mixins';

// FONT FAMILY //
// Nunito Sans //
@font-face {
    font-family: 'NunitoSans Regular';
    src: url('../assets/fonts/NunitoSans-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans Bold';
    src: url('../assets/fonts/NunitoSans-Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans Italic';
    src: url('../assets/fonts/NunitoSans-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}