@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.specialty {

    &__items {
        @include tablet {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1rem;
            // flex: 1 1 2;
        }

        &-info {
            padding-top: 1rem;
    
            & img {
                width: 100%;
                height: auto;
                padding-bottom: 0.5rem;
                object-fit: cover;

                @include tablet {
                    width: 18rem;
                    height: 18rem;
                }

                @include desktop {
                    width: 20rem;
                    width: 20rem;
                }
            }
        }
    }
}