@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home {
    padding: 0rem;
    text-align: center;

    &__text {
        padding-top: 2rem;
    }
}