@use "./variables" as *;
@use "./mixins" as *;
@use "./typography" as *;

* {
    font-family: "NunitoSans Regular", sans-serif;
}

h1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;

    @include tablet {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}

h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;

    @include tablet {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

p {
    font-size: 0.813rem;
    line-height: 1.125rem;
    font-weight: 400;

    @include tablet {
        font-size: 0.875rem;
        line-height: 1.375rem;
    }
}

h3,
link,
button,
.label {
    font-size: 0.8125rem;
    line-height: 1.25rem;
    font-weight: 600;

    @include tablet {
        font-size: 0.875rem;
        line-height: 1.125rem;
    }
}

h4 {
    font-size: 0.6875rem;
    line-height: 1rem;

    @include tablet {
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
}

.p-large {
    font-size: 0.9375rem;
    line-height: 1.625rem;

    @include tablet {
        font-size: 1rem;
        line-height: 1.75rem;
    }
}

.p-medium {
    font-size: 0.8125rem;
    line-height: 1.25rem;

    @include tablet {
        font-size: 0.875rem;
        line-height: 1.375rem;
    }
}

.p-small {
    font-size: 0.6875rem;
    line-height: 1rem;

    @include tablet {
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
}